import React from 'react';

const Footer = (props) => {
  return (
    <div className="mastfoot">
      &copy; 2015 - 2021 PureLynx LLC
    </div>
  );
}

export default Footer;
