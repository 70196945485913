import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LeagueContext } from '../context/LeagueContext';
import Logo from './core/Logo';
import Fetcher from './utils/Fetcher';
import API from '../utils/API';

const Leaderboard = (props) => {
  const [league, ] = useContext(LeagueContext);

  const renderLeaderboard = (data) => {
    const week = data.data;
    if (!week.results) {
      week.results = [];
    }
    let rank = 0;
    let previousPoints = Number.MAX_SAFE_INTEGER;
    return (
      <div>
        <h2>{week.name}</h2>
        <table className="leader_board">
          <thead>
            <tr>
              <th className="leader_board align_left">RANK</th>
              <th className="leader_board align_left">NAME</th>
              <th className="leader_board align_right">POINTS</th>
              <th className="leader_board align_right">WINS</th>
              <th className="leader_board align_right">LOSSES</th>
              <th className="leader_board align_right">TIES</th>
            </tr>
          </thead>
          <tbody>
            {week.results.map((result, counter) => {
              if (result.points < previousPoints) {
                rank = counter;
                previousPoints = result.points;
              }
              const resultsClass = result.points > 0 ? 'win' : result.points < 0 ? 'loss' : '';
              return (
              <tr className="divider" key={counter}>
                <td className="leader_board align_left">{rank + 1}</td>
                <td className="leader_board align_left">{result.userNickname || result.userEmail}</td>
                <td className={"leader_board align_right " + resultsClass}>{+result.points.toFixed(1)}</td>
                <td className="leader_board align_right">{result.wins}</td>
                <td className="leader_board align_right">{result.losses}</td>
                <td className="leader_board align_right">{result.ties}</td>
              </tr>);
            })}
          </tbody>
        </table>
      </div>
    )
  };

  const showLeaderboard = league && league.id ? (
      <Fetcher action={async () => await API.get(`/weeks/${props.weekdId || 'current'}/results?leagueId=${league.id}`)}>
        {(data) => renderLeaderboard(data)}
      </Fetcher>
  ) : '';

  return (
    <div className="container">
      <Logo />
      <div className="cover inner">
        <br/>
        <h2>
          <Link to="/games">NFL Games</Link>
        </h2>
        <br/>
        <h2>
          {league && league.title ? league.title : ''}
        </h2>
        {showLeaderboard}
        <br/>
        <br/>
      </div>
    </div>
  );
}

export default Leaderboard;
