import React, { useContext, useState } from 'react';
import { useRecaptcha, Badge } from 'react-recaptcha-hook';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { LeagueContext, getLeague } from '../../context/LeagueContext';
import { useHotkeys } from 'react-hotkeys-hook';
import Spinner from 'react-bootstrap/Spinner';
import API from '../../utils/API';

const Register = (props) => {
  const [state, setState] = useState(false);
  const [, setUser] = useContext(UserContext);
  const [, setLeague] = useContext(LeagueContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errors, setErrors] = useState('');
  const execute = useRecaptcha({ sitekey: '6LfEQ9YZAAAAAOynAfrT_q69tcei_S8iis8U3tjI', hideDefaultBadge: true });
  let history = useHistory();

  useHotkeys('escape', () => { history.push("/"); });

  const handleChange = (event) => {
    if (event.target.name === 'email') {
      setEmail(event.target.value);
    } else if (event.target.name === 'password') {
      setPassword(event.target.value);
    } else if (event.target.name === 'passwordConfirmation') {
      setPasswordConfirmation(event.target.value);
    }
    setErrors('');
  };

  const handleSubmit = async (event) => {
    setState(true);
    event.preventDefault();
    setPassword('');
    setPasswordConfirmation('');
    if (password !== passwordConfirmation) {
      setErrors('Password mismatch')
      return;
    }
    try {
      const token = await execute('register');
      const res = await API.post('/users', { email, password, token });
      setUser(res.data);
      setLeague(await getLeague());
      history.push('/');
    } catch (error) {
      if (error && error.response && error.response.data) {
        setErrors(error.response.data.message);
      }
      setUser({});
      setLeague({});
    }
    setState(false);
  };

  return state ? (<Spinner className="centered-spinner" animation="border" role="status" variant="primary" />) : (
    <>
      <div className="popup">
        <Link className="top-right" to="/"><img className="close-panel" src="x-png-icon-8.jpg" alt="X" /></Link>
        <h1 className="condensed">Register</h1>
        <h2 className="red">{errors}</h2>
        <form className="form-wrapper" onSubmit={handleSubmit}>
          <div className="form-group">
            <input className="form-control" type="email" name="email" placeholder="Email Address" value={email} onChange={handleChange} required></input>
            <input className="form-control" type="password" name="password" placeholder="Password" value={password} onChange={handleChange} required></input>
            <input className="form-control" type="password" name="passwordConfirmation" placeholder="Password Confirmation" value={passwordConfirmation} onChange={handleChange} required></input>
            <button className="form-control btn btn-submit" type="submit">REGISTER</button>
          </div>
        </form>
        <Link className="login" to="/login">Member Login</Link>
        {props.children}
      </div>
      <br/>
      <Badge />
      {props.children}
    </>
  );
};

export default Register;
