import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import useFetcher from '../../utils/useFetcher';

const Fetcher = ({ action, children }) => {
  const [data, loading, error] = useFetcher(action);

  if (loading) {
    return <Spinner className="centered-spinner" animation="border" role="status" variant="primary"/>;
  }
  if (error) {
    return (
      <Alert variant="warning">
        {error}
      </Alert>
    );
  }
  return data ? children(data) : null;
};

export default Fetcher;
