import { useState, useEffect } from 'react';

function useFetcher(action) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const actionData = await action();
        setData(actionData);
      } catch (err) {
        setError(JSON.stringify(err));
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [action]);

  return [data, loading, error];
}

export default useFetcher;
