import React from 'react';
import Fetcher from './utils/Fetcher';
import API from '../utils/API';
import Slider from './Slider';

const Games = (props) => {
  const renderWeek = (data) => {
    let title = '';
    try {
      const week = data.data;
      title = week.name;
    } catch (err) {}
    return <p className="week" style={{paddingTop: "30px", paddingBottom: "10px"}}>{title}</p>;
  };

  const updatedPick = async (gameId, forecast) => {
    try {
      const picks = await API.get(`/games/${gameId}/picks`);
      const pickId = picks.data.id;
      await API.put(`/picks/${pickId}`, { forecast });
    } catch (err) {}
  };

  const formatDate = (dateString) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const date = new Date(dateString);
    const time = date.toLocaleTimeString().split(' ');
    const hourMin = time[0].split(':');
    hourMin.pop();
    time[0] = hourMin.join(':');
    const timezone = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ').pop();
    return days[date.getDay()] + ' ' + time.join(' ') + ' ' + timezone;
  }

  const renderGames = (data) => {
    const now = new Date()
    let games;
    let results = [0, 0, 0];
    let totalPoints = 0;
    try {
      games = data.data.map((game) => {
        const forecast = game.picks && game.picks.length === 1 ? game.picks[0].forecast : 50;
        const points = game.picks && game.picks.length === 1 ? game.picks[0].points : undefined;
        const scoreClass = points !== undefined ? points > 0 ? 'win' : points < 0 ? 'loss' : 'tie' : '';
        if (points !== undefined) {
          totalPoints += points;
          if (points > 0) {
            ++results[0];
          } else if (points < 0) {
            ++results[1];
          } else {
            ++results[2];
          }
        }
        let controls;
        if (game.phase === 'SCHEDULED' && now <= new Date(game.gameTime)) {
          controls = (
            <span className="venue">
              <span>{game.awayTeam} @ {game.homeTeam}</span>
              <br/>
              <Slider gameId={game.id} forecast={forecast} onChange={updatedPick} editable />
              <div className="time">
                {formatDate(game.gameTime)}
              </div>
            </span>
          );
        } else {
          const isRedZone = game.isRedZone;
          const awayPossessionClass = (game.phase === 'IN_PROGRESS' && game.possessionTeam === game.awayTeam) ? (isRedZone ? 'arrow-left-redzone' : 'arrow-left') : 'empty';
          const homePossessionClass = (game.phase === 'IN_PROGRESS' && game.possessionTeam === game.homeTeam) ? (isRedZone ? 'arrow-right-redzone' : 'arrow-right') : 'empty';
          let time;
          if (game.phase === 'FINAL' || game.phase === '') {
            time = <div className="time">Final</div>;
          } else if (game.phase === 'FINAL_OVERTIME') {
            time = <div className="time">Final/OT</div>;
          } else if (game.phase === 'END_PERIOD') {
            const quarter = game.period > 4 ? 'OT' : game.period === 4 ? 'Regulation' : `${game.period}Q`;
            const details = (game.details && (game.period === 1 || game.period === 3)) ? ` • ${game.details}` : '';
            time = <div className="time">End of {quarter}{details}</div>;
          } else if (game.phase === 'HALFTIME') {
            time = <div className="time">Halftime</div>;
          } else if (game.phase === 'SUSPENDED') {
            time = <div className="time">Suspended</div>;
          } else if (game.phase === 'IN_PROGRESS') {
            const quater = game.period > 4 ? 'OT' : `${game.period}Q`;
            const details = game.details ? ` • ${game.details}` : '';
            time = <div className="time">{game.gameClock} {quater}{details}</div>;
          }
          controls = (
            <span className="score">
              <div className={awayPossessionClass} />
              <span className={scoreClass}>{game.awayScore} - {game.homeScore}</span>
              <div className={homePossessionClass} />
              <br />
              <Slider gameId={game.id} forecast={forecast} />
              {time}
            </span>
          );
        }
        return (
          <div key={game.id} className="game">
            <div className="left-justify vstack noselect">
              <img src={game.awayLogo} alt={game.awayTeam} className="team" />
              <p className="team-record">({game.awayRecord})</p>
            </div>
            <div className="center-justify noselect">
              {controls}
              <br />
            </div>
            <div className="right-justify vstack noselect">
              <img src={game.homeLogo} alt={game.homeTeam} className="team" />
              <p className="team-record">({game.homeRecord})</p>
            </div>
          </div>
        );
      });
    } catch (err) {}
    if (results[2] === 0) {
      results.pop();
    }
    results = results.join(' - ');
    const pointsStatus = totalPoints > 0 ? 'win' : totalPoints < 0 ? 'loss' : '';
    return (
      <div className="games-results">
        <div className="games">
          {games}
        </div>
        <div className="record">
          Record: {results}
        </div>
        <div className="points">
          Points: <span className={pointsStatus}>{+totalPoints.toFixed(1)}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <Fetcher action={async () => await API.get(`/weeks/${props.weekId ? props.weekId : 'current'}`)}>
        {(data) => renderWeek(data)}
      </Fetcher>
      <Fetcher action={async () => await API.get(`/games?weekId=${props.weekdId ? props.weekId : 'current'}`)}>
        {(data) => renderGames(data)}
      </Fetcher>
    </>
  );
};

export default Games;

