import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../context/UserContext';

const Slider = (props) => {
  const [forecast, setForecast] = useState(props.forecast);
  const [user, ] = useContext(UserContext);

  useEffect(() => {
    const adjustedForecast = props.forecast < 0 ? 0 : props.forecast > 100 ? 100 : props.forecast;
    setForecast(adjustedForecast);
  }, [props.forecast]);

  const parseTransform = (transform) => {
    const obj = {};
    for (let i in transform = transform.match(/(\w+\((-?\d+\.?\d*e?-?\d*,?)+\))+/g)) {
      let kvp = transform[i].match(/[\w.-]+/g);
      obj[kvp.shift()] = kvp;
    }
    return obj;
  };

  const getSliderValue = (slider) => {
    const thumb = slider.getElementsByClassName('thumb');
    return (((parseInt(parseTransform(thumb[0].getAttribute('transform'))['translate'][0])) - 20) / 2 >> 0);
  };

  const getPosition = (value) => value*2 + 20;
  const getPercentOffset = (value) => value >= 100 ? 14 : 11;

  const pointerMove = (event) => {
    if (!props.editable) {
      return;
    }
    const slider = event.target.ownerSVGElement || event.target;
    const x = event.clientX - slider.getBoundingClientRect().left;
    const offsetX = x < 20 ? 20 : x > 220 ? 220 : x;
    const thumb = slider.getElementsByClassName('thumb');
    thumb[0].setAttribute('transform', 'translate(' + [offsetX, 20] + ')');
    const percentageElement = slider.getElementsByClassName('percentage');
    const forecastElement = slider.getElementsByClassName('forecast');
    let value = getSliderValue(slider);
    if (value < 50) {
      value = 100 - value;
    }
    forecastElement[0].innerHTML = value;
    const offset = getPercentOffset(value);
    percentageElement[0].setAttribute('transform', 'translate(' + [offsetX + offset, 20] + ')');
  };

  const pointerDown = (event) => {
    if (!props.editable) {
      return;
    }
    const slider = event.target.ownerSVGElement || event.target;
    slider.onpointermove = pointerMove;
    slider.setPointerCapture(event.pointerId);
    pointerMove(event);
  };

  const pointerUp = (event) => {
    if (!props.editable) {
      return;
    }
    const slider = event.target.ownerSVGElement || event.target;
    slider.onpointermove = null;
    slider.releasePointerCapture(event.pointerId);
    const value = getSliderValue(slider);
    setForecast(value);
    if (props.onChange) {
      props.onChange(props.gameId, value);
    }
  };

  const bgGradientId = `bgGradient-${props.gameId}`;
  const svgClasses = ["slider"]
  if (props.editable) {
    svgClasses.push("active")
  }

  return (
    <>
      <svg className={svgClasses.join(' ')} id={"slider-" + props.gameId} xmlns="http://www.w3.org/2000/svg" version="1.1" onPointerDown={pointerDown} onPointerUp={pointerUp}>
        <defs>
          <radialGradient id={bgGradientId}>
            <stop offset="30%" stopColor="#fff" stopOpacity="1" />
            <stop offset="90%" stopColor="#000" stopOpacity="0" />
          </radialGradient>
        </defs>
        <g className="track">
          <line fill="none" stroke="#ccc" strokeMiterlimit="10" x1="20" y1="36" x2="220" y2="36" />
        </g>
        <g className="divider">
          <line fill="none" stroke="#ddd" strokeMiterlimit="10" x1="120" y1="28" x2="120" y2="44" />
        </g>
        {user.id ? (
          <>
            <g className="thumb" transform={"translate(" + getPosition(forecast) + ",20)"}>
            {props.editable ? (
              <>
                <circle cy="16" r="12" fill={"url(#" + bgGradientId + ")"} style={{fillOpacity: 0.75}} />
                <circle cy="16" r="7" stroke="#000" fill="#fff" style={{fillOpacity: 0.5}} />
              </>
              ) : (
              <>
                <circle cy="16" r="2.5" stroke="#fff" fill="#fff" style={{fillOpacity: 1.0}} />
              </>
              )
            }
            </g>
            <g className="percentage" transform={"translate(" + (getPosition(forecast) + getPercentOffset(forecast)) + ",20)"}>
              <text className="forecast" y="4" textAnchor="end">{forecast < 50 ? 100 - forecast : forecast}</text>
              <text className="percent">%</text>
            </g>
          </>
        ) : <></>}
      </svg>
      {props.children}
    </>
  )
};

export default Slider;
