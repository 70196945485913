import React from 'react';
import API from '../utils/API';

export const LeagueContext = React.createContext([{}, () => {}]);
export const getLeague = async (id = 'default') => {
  try {
    const res = await API.get(`/leagues/${id}`);
    return res.data;
  } catch (err) {}
  return {};
};
