import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { UserContext } from './context/UserContext';
import { LeagueContext } from './context/LeagueContext';

const Environment = (props) => {
  const [user, setUser] = useState({});
  const [league, setLeague] = useState({});

  return  (
    <UserContext.Provider value={[user, setUser]}>
      <LeagueContext.Provider value={[league, setLeague]}>
        {props.children}
      </LeagueContext.Provider>
    </UserContext.Provider>
  );
};

ReactDOM.render(
    <Environment>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Environment>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
