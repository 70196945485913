import React from 'react';

const Logo = (props) => {
  return (
    <div className="masthead">
      <img className="brand" src='/pickSavvy.png' alt='pickSavvy' />
    </div>
  );
}

export default Logo;