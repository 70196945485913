import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { LeagueContext, getLeague } from '../../context/LeagueContext';
import { useHotkeys } from 'react-hotkeys-hook';
import Spinner from 'react-bootstrap/Spinner';
import API from '../../utils/API';

const Login = (props) => {
  const [state, setState] = useState(false);
  const [, setUser] = useContext(UserContext);
  const [, setLeague] = useContext(LeagueContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState('');
  let history = useHistory();

  useHotkeys('escape', () => { history.push("/"); });

  const handleChange = (event) => {
    if (event.target.name === 'email') {
      setEmail(event.target.value);
    } else if (event.target.name === 'password') {
      setPassword(event.target.value);
    }
    setErrors('');
  };

  const handleSubmit = async (event) => {
    setState(true);
    event.preventDefault();
    setPassword('');
    try {
      const res = await API.post('/login', { email, password });
      if (res.status === 200) {
        setEmail('');
        setUser(res.data);
        setLeague(await getLeague());
        props.history.push('/');
      }
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        setErrors('Invalid email or password');
      }
      setLeague({});
      setUser({});
    }
    setState(false);
  };

  return state ? (<Spinner className="centered-spinner" animation="border" role="status" variant="primary" />) : (
    <div className="popup">
      <Link className="top-right" to="/"><img className="close-panel" src="x-png-icon-8.jpg" alt="X" /></Link>
      <h1 className="condensed">Member Login</h1>
      <h2 className="red">{errors}</h2>
      <form className="form-wrapper" onSubmit={handleSubmit}>
        <div className="form-group">
          <input className="form-control" type="email" name="email" placeholder="Email Address" value={email} onChange={handleChange} required></input>
          <input className="form-control" type="password" name="password" placeholder="Password" value={password} onChange={handleChange} required></input>
          <button className="form-control btn btn-submit" type="submit">LOGIN</button>
        </div>
      </form>
      <Link className="login" to="/forgot">Forgot Password</Link>
      <span className="padding-left-right-7">&bull;</span>
      <Link className="login" to="/register">Register</Link>
      {props.children}
    </div>
  );
}

export default Login;
