import React, { useContext, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { UserContext } from '../../context/UserContext';
import { useHotkeys } from 'react-hotkeys-hook';
import Spinner from 'react-bootstrap/Spinner';
import API from '../../utils/API';

const Settings = forwardRef((props, ref) => {
  const modalDialog = useRef();
  const [state, setState] = useState(false);
  const [user, setUser] = useContext(UserContext);
  const [email, setEmail] = useState(user.email);
  const [nickname, setNickname] = useState(user.nickname);
  const [errors, setErrors] = useState('');

  useImperativeHandle(ref, () => ({
    show: () => {
      setEmail(user.email);
      setNickname(user.nickname);
      modalDialog.current.style.display = 'inherit';
    }
  }));

  const hide = () => {
    modalDialog.current.style.display = 'none';
    setEmail('');
    setNickname('');
  };

  const hideSettings = (event) => {
    event.preventDefault();
    hide();
  }
  const cancelSettings = (event) => {
    if (event.target === modalDialog.current) {
      event.preventDefault();
      hide();
    }
  }

  useHotkeys('escape', () => { hide(); });

  const handleChange = (event) => {
    if (event.target.name === 'email') {
      setEmail(event.target.value);
    } else if (event.target.name === 'nickname') {
      setNickname(event.target.value);
    }
    setErrors('');
  };

  const handleSubmit = async (event) => {
    setState(true);
    event.preventDefault();
    try {
      const res = await API.patch('/users/me', { email, nickname });
      if (res.status === 200) {
        setUser(res.data);
        hide();
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        setErrors(error.response.data.message);
      }
    }
    setState(false);
  };

  return state ? (<Spinner className="centered-spinner" animation="border" role="status" variant="primary" />) : (
    <div ref={modalDialog} className="fullscreen-container" onClick={cancelSettings}>
      <div className="popup">
        <a className="top-right" href="/lobby" onClick={hideSettings}><img className="close-panel" src="x-png-icon-8.jpg" alt="X" /></a>
        <h1 className="condensed">User Settings</h1>
        <h2 className="red">{errors}</h2>
        <form className="form-wrapper" onSubmit={handleSubmit}>
          <div className="form-group">
            <span data-tip="Email Address">
              <input className="form-control" type="email" name="email" placeholder="Email Address" value={email} onChange={handleChange} required></input>
            </span>
            <span data-tip="Nickname">
              <input className="form-control" type="text" name="nickname" placeholder="Nickname" value={nickname} onChange={handleChange} required></input>
            </span>
            <button className="form-control btn btn-submit" type="submit">UPDATE</button>
          </div>
        </form>
        {props.children}
      </div>
    </div>
  );
});

export default Settings;
