import React, { useContext, useEffect, useRef } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Settings from './components/auth/Settings';
import Leaderboard from './components/Leaderboard';
import Games from './components/Games';
import Logo from './components/core/Logo';
import Footer from './components/core/Footer';
import Spinner from 'react-bootstrap/Spinner';
import { UserContext } from './context/UserContext';
import { LeagueContext, getLeague } from './context/LeagueContext';
import useFetcher from './utils/useFetcher';
import API from './utils/API';

const getInitialUser = async () => {
  try {
    const res = await API.get('/users/me');
    return res.data;
  } catch (err) {
    return {};
  }
};

const App = (props) => {
  const [user, setUser] = useContext(UserContext);
  const [, setLeague] = useContext(LeagueContext);
  const [initialUser, loadingInitialUser, ] = useFetcher(getInitialUser);
  const settingsRef = useRef();
  let content;
  let loginUser = null;

  const showSettings = (event) => {
    event.preventDefault();
    event.target.blur();
    if (settingsRef) {
      settingsRef.current.show();
    }
  };

  if ((initialUser === null || loadingInitialUser) &&
      (Object.entries(user).length === 0 && user.constructor === Object)) {
    content = <Spinner className="centered-spinner" animation="border" role="status" variant="primary" />;
    if (initialUser !== null) {
      loginUser = initialUser;
      (async () => { setLeague(await getLeague()); })();
    }
  } else {
    if (user.id) {
      const logout = async (event) => {
        try {
          await API.get('/logout');
          setLeague({});
          setUser({});
        } catch (error) {}
      };

      content = (
        <>
          <div className="right">
            <Link to="/">Home</Link> | <Link to="/settings" onClick={showSettings}>{user.email}</Link> | <Link to="/" onClick={logout}>Logout</Link>
          </div>
          <Switch>
            <Route path='/' component={Leaderboard} exact />
            <Route path='/games' render={(props) => <Games {...props} />} />
            <Route component={Leaderboard} />
          </Switch>
        </>
      );
    } else {
      content = (
        <div className="container">
          <Logo />
          <Switch>
            <Route path='/' component={Login} exact />
            <Route path='/login' component={Login} />
            <Route path='/register' component={Register} />
            <Route component={Login} />
          </Switch>
        </div>
      );
    }
  }

  useEffect(() => {
    if (loginUser) {
      setUser(loginUser);
    }
  }, [setUser, loginUser])

  return (
    <>
      <Settings ref={settingsRef} />
      <main className="container-fluid">
        {content}
        {props.children}
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default App;
